import { AppUserModel, api } from '@/lib/api/client';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export type AppStoreType = {
  impersonatedUserId: number | null;
  user: AppUserModel | null;
  appLoading: boolean;
  // email address of the owner of the token - useful when user is impersontaed
  originalUserEmail: string | null;
  siderbarOpen: boolean;
};

export const appStore = create<AppStoreType>()(
  immer((set) => {
    return {
      originalUserEmail: null,
      impersonatedUserId: null,
      user: null,
      appLoading: true,
      siderbarOpen: false,
    };
  })
);

export function closeSidebar() {
  appStore.setState((s) => {
    s.siderbarOpen = false;
  });
}

export function toggleSidebar() {
  appStore.setState((s) => {
    s.siderbarOpen = !s.siderbarOpen;
  });
}

// TODO: notifications
export async function refetchUser() {
  try {
    const me = await api.GET('/users/me');

    if (!me.data) throw new Error('Something went wrong...');

    appStore.setState((store) => {
      store.user = me.data;
    });
    return;
  } catch (e) {
    console.error(e);
  }

  appStore.setState((store) => {
    store.user = null;
  });
}

export async function impersonateUser(userId: number) {
  appStore.setState((store) => {
    store.impersonatedUserId = userId;
  });
  await refetchUser();
}

export function stopImperonation() {
  appStore.setState((store) => {
    store.impersonatedUserId = null;
  });
  refetchUser();
}
