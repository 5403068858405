export const appConfig: {
  backendUrl: string;
  websiteUrl: string;
  adminOnlyPages: string[];
} = {
  backendUrl: import.meta.env.VITE_BACKEND_URL!,
  websiteUrl: import.meta.env.VITE_WEBSITE_URL!,
  adminOnlyPages: ['/users', '/site-management'],
};

if (!appConfig.backendUrl) {
  throw new Error('missing VITE_BACKEND_URL');
}
if (!appConfig.websiteUrl) {
  throw new Error('missing VITE_WEBISTE_URL');
}
console.log(`api created with api url: `, appConfig.backendUrl);
