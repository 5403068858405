import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { GoogleIcon } from './GoogleIcon';
import { useViewportSize } from '@mantine/hooks';
import { theme } from '@/theme';

export function LoginComponent() {
  const { width } = useViewportSize();

  function loginWithGoogle() {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({ prompt: 'select_account' });

    signInWithPopup(getAuth(), provider);
  }

  return (
    <div className="flex h-screen flex-1 flex-col items-center justify-center">
      <div
        className="flex flex-col p-8 rounded-xl"
        style={{
          boxShadow: `0px 1px 10px -3px ${theme.colors?.['brand']?.[9]}`,
          width: width > 600 ? '468px' : '340px',
          height: width > 600 ? '345px' : '260px',
        }}
      >
        <div className="flex flex-col items-center gap-2">
          <strong className="text-sm shoju">Determinacja</strong>
          <div className="shoju">Dashboard</div>
        </div>
        <div className="flex flex-1 flex-col items-center justify-center">
          <button
            onClick={loginWithGoogle}
            className="btn btn-ghost mx-auto flex w-1/2 items-center gap-4"
          >
            <GoogleIcon className="h-10 w-10" />
            <div>Login with google</div>
          </button>
        </div>
      </div>
    </div>
  );
}
