// Import the functions you need from the SDKs you need
import { appStore } from '@/stores/app.store';
import { notifications } from '@mantine/notifications';
import { type Messaging, getMessaging } from 'firebase/messaging';
import { type FirebaseApp, initializeApp } from 'firebase/app';
import { type Auth, getAuth } from 'firebase/auth';
import { api, DgError } from './api/client';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
export let app!: FirebaseApp;
export let auth!: Auth;
export let messaging!: Messaging;

app = initializeApp({
  apiKey: 'AIzaSyCV58nJKPinbnWmsVEpTm-VXoUggrh89fk',
  authDomain: 'determination-f436e.firebaseapp.com',
  projectId: 'determination-f436e',
  storageBucket: 'determination-f436e.appspot.com',
  messagingSenderId: '321718844173',
  appId: '1:321718844173:web:5c37251823ba877ce44e44',
  measurementId: 'G-RC36FHBL69',
});
auth = getAuth(app);
messaging = getMessaging(app);

auth.onAuthStateChanged(async (user) => {
  if (!user) {
    appStore.setState((state) => {
      state.user = null;
      state.appLoading = false;
      state.originalUserEmail = null;
    });
    return;
  }

  const appUser = await api.GET('/users/me');

  if (!appUser.data) {
    appStore.setState((state) => {
      state.user = null;
      state.appLoading = false;
      state.originalUserEmail = user.email;
    });

    if ((appUser.error as DgError)?.code === 'USER_NOT_FOUND') {
      notifications.show({
        title: 'Brak dostępu',
        message: `Użytkownik ${user.email} nie został znaleziony w systmie.`,
      });
    } else {
      notifications.show({
        title: 'Wystąpił błąd',
        message: 'Wystąpił nieoczekiwany błąd podczas logowania. Spróbuj ponownie później.',
      });
    }

    auth.signOut();

    return;
  }

  appStore.setState((state) => {
    state.user = appUser.data;
    state.appLoading = false;
    state.originalUserEmail = user.email;
  });

  console.log('user updated to ', appUser.data);
});
